import { updateTotalVeCHR } from './actions'
import { AppState, AppDispatch } from './../index'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// export function useProtocolData(): [ProtocolData | undefined, (protocolData: ProtocolData) => void] {
//   const [activeNetwork] = useActiveNetworkVersion()
//   const protocolData: ProtocolData | undefined = useSelector(
//     (state: AppState) => state.protocol[activeNetwork.id]?.data
//   )

//   const dispatch = useDispatch<AppDispatch>()
//   const setProtocolData: (protocolData: ProtocolData) => void = useCallback(
//     (protocolData: ProtocolData) => dispatch(updateProtocolData({ protocolData, networkId: activeNetwork.id })),
//     [activeNetwork.id, dispatch]
//   )
//   return [protocolData, setProtocolData]
// }

export function useTotalVeChr(): [number | undefined, (totalVeCHR: number | undefined) => void] {
  const veChrAmount: number | undefined = useSelector((state: AppState) => state.voter.totalVeCHR)

  const dispatch = useDispatch<AppDispatch>()
  const _updateTotalVeCHR: (totalVeCHR: number | undefined) => void = useCallback(
    (totalVeCHR: number | undefined) => dispatch(updateTotalVeCHR({ totalVeCHR })),
    [dispatch]
  )
  return [veChrAmount, _updateTotalVeCHR]
}
