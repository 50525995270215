import { useEffect, useMemo, useState } from 'react'
import { BigNumber } from 'ethers'
// import { NEVER_RELOAD, useSingleCallResult } from '../state/multicall/hooks'
import { useVoterV3Contract } from './useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'

/**
 * Get total voting power
 */
export default function useTotalVeCHR(): BigNumber | undefined {
  return undefined
  // const VoterContract = useVoterV3Contract()
  // const totalVotingPower = useSingleCallResult(VoterContract, 'totalWeight', [], NEVER_RELOAD)

  // return useMemo(() => {
  //   if (totalVotingPower.loading) return null
  //   if (totalVotingPower.result) {
  //     return totalVotingPower.result[0]
  //   }
  //   return undefined
  // }, [totalVotingPower.loading, totalVotingPower.result])
}

export function useGetTotalVeCHR(): BigNumber | undefined {
  const VoterContract = useVoterV3Contract()
  // const blockNumber = useBlockNumber()
  const result = useSingleCallResult(VoterContract ? VoterContract : null, 'totalWeight', [], {
    gasRequired: 8000000,
    blocksPerFetch: Infinity,
  })

  const [VeCHR, setVeCHR] = useState<BigNumber | undefined>(undefined)

  useEffect(() => {
    if (!VoterContract) return
    if (!result) return
    if (result.loading) return
    if (result.result && result.result[0]) {
      setVeCHR(result.result[0])
    }
    return
  }, [VoterContract, result.loading])
  return VeCHR
}
