import { AppState, AppDispatch } from './../index'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSelectedGauge } from './actions'

// export function useAllGaugesData(): {
//   [address: string]: { data: PoolData | undefined; lastUpdated: number | undefined }
// } {
//   const [network] = useActiveNetworkVersion()
//   return useSelector((state: AppState) => state.gauges.byAddress[network.id] ?? {})
// }

// export function useUpdatePoolData(): (pools: PoolData[]) => void {
//   const dispatch = useDispatch<AppDispatch>()
//   const [network] = useActiveNetworkVersion()
//   return useCallback((pools: PoolData[]) => dispatch(updateGaugesData({ pools, networkId: network.id })), [
//     dispatch,
//     network.id,
//   ])
// }

// export function useAddGaugesKeys(): (addresses: string[]) => void {
//   const dispatch = useDispatch<AppDispatch>()
//   const [network] = useActiveNetworkVersion()
//   return useCallback((poolAddresses: string[]) => dispatch(addGaugesKeys({ poolAddresses, networkId: network.id })), [
//     dispatch,
//     network.id,
//   ])
// }

// export function useGaugesDatas(gaugesAddresses: string[]): PoolData[] {
//   const allGaugesData = useAllGaugesData()
//   const addGaugesKeys = useAddGaugesKeys()

//   const untrackedAddresses = gaugesAddresses.reduce((accum: string[], address) => {
//     if (!Object.keys(allGaugesData).includes(address)) {
//       accum.push(address)
//     }
//     return accum
//   }, [])

//   useEffect(() => {
//     if (untrackedAddresses) {
//       addGaugesKeys(untrackedAddresses)
//     }
//     return
//   }, [addGaugesKeys, untrackedAddresses])

//   // filter for pools with data
//   const gaugesWithData = gaugesAddresses
//     .map((address) => {
//       const gaugeData = allGaugesData[address]?.data
//       return gaugeData ?? undefined
//     })
//     .filter(notEmpty)

//   return gaugesWithData
// }

export function useSelectedGauge(): [string | undefined, (poolAddress: string | undefined) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const selectedGauge = useSelector((state: AppState) => state.gauges.selectedGaugeAddress)

  const setSelectedGauge = useCallback(
    (address: string | undefined) => dispatch(updateSelectedGauge({ poolAddress: address })),
    [dispatch]
  )

  return [selectedGauge, setSelectedGauge]
}
