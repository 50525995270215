import { useUpdatePoolData, useAllPoolData, useAddPoolKeys, useSelectedPool } from './hooks'
import { useEffect, useMemo } from 'react'
import { useTopPoolAddresses } from 'data/pools/topPools'
import { usePoolDatas } from 'data/pools/poolData'
import { POOL_HIDE } from '../../constants'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { useGetAllCLPairs, useGetCLPair } from '../../hooks/usePairAPIV3'
import { PoolData } from './reducer'
import { NULL_ADDRESS } from 'constants/Constants'

export default function Updater(): null {
  // updaters
  const [selectedPool, setSelectedPool] = useSelectedPool()
  const [currentNetwork] = useActiveNetworkVersion()
  const updatePoolData = useUpdatePoolData()
  const addPoolKeys = useAddPoolKeys()

  const allPoolData = useAllPoolData()

  // const { loading, error, addresses } = useTopPoolAddresses()
  const pairs = useGetAllCLPairs()

  // add top pools on first load
  useEffect(() => {
    if (pairs) {
      const pools = pairs.filter((pair) => pair.pair_address !== NULL_ADDRESS)
      addPoolKeys(pools)
    }
  }, [addPoolKeys, pairs])

  // load data for pools we need to hide
  // useEffect(() => {
  //   addPoolKeys(POOL_HIDE[currentNetwork.id])
  // }, [addPoolKeys, currentNetwork.id])

  // detect for which addresses we havent loaded pool data yet
  const unfetchedPoolAddresses = useMemo(() => {
    return Object.keys(allPoolData).reduce((accum: string[], key) => {
      const poolData = allPoolData[key]
      if (
        (!poolData.data || !poolData.lastUpdated) &&
        poolData.dysonData &&
        poolData.dysonData.pair_address !== '0x0000000000000000000000000000000000000000'
      ) {
        accum.push(poolData.dysonData.clPool)
      }
      return accum
    }, [])
  }, [allPoolData])

  // update unloaded pool entries with fetched data
  const { error: poolDataError, loading: poolDataLoading, data: poolDatas } = usePoolDatas(unfetchedPoolAddresses)

  useEffect(() => {
    if (poolDatas && !poolDataError && !poolDataLoading && Object.keys(poolDatas).length > 0) {
      const pools = Object.values(poolDatas)
        .map((poolData) => {
          const baseData = Object.values(allPoolData).find((pool) => pool.dysonData?.clPool === poolData.address)
          if (baseData) {
            return [poolData, baseData.dysonData?.pair_address]
          }
          return null
        })
        .filter((pool) => pool !== null) as [PoolData, string][]
      updatePoolData(pools)
    }
  }, [allPoolData, poolDataError, poolDataLoading, poolDatas, updatePoolData])

  const selectedPoolData = useGetCLPair(selectedPool)
  useEffect(() => {
    if (selectedPoolData) {
      addPoolKeys([selectedPoolData])
    }
  }, [addPoolKeys, selectedPoolData])

  const { error: selectedPoolDataError, loading: selectedPoolDataLoading, data: selectedPoolDatas } = usePoolDatas(
    selectedPool ? [selectedPool] : []
  )

  useEffect(() => {
    if (
      selectedPoolDatas &&
      !selectedPoolDataError &&
      !selectedPoolDataLoading &&
      Object.keys(selectedPoolDatas).length > 0
    ) {
      const pools = Object.values(selectedPoolDatas)
        .map((poolData) => {
          const baseData = Object.values(allPoolData).find((pool) => pool.dysonData?.clPool === poolData.address)
          if (baseData) {
            return [poolData, baseData.dysonData?.pair_address]
          }
          return null
        })
        .filter((pool) => pool !== null) as [PoolData, string][]
      if (pools.length > 0) {
        updatePoolData(pools)
      }
    }
  }, [selectedPool, selectedPoolDataError, selectedPoolDataLoading, updatePoolData])
  //// END SELECTED POOL /////

  return null
}
