import React, { useEffect, useState } from 'react'
import Column, { AutoColumn, ColumnEnd, ColumnStart } from 'components/Column'
import { StyledInternalLink, TYPE } from 'theme'
import Row, { AutoRow, ResponsiveRow, RowBetween, RowFixed, TableRowBetween } from 'components/Row'
import useTheme from 'hooks/useTheme'
import { useProtocolTransactions } from 'state/protocol/hooks'
import { PageWrapper, ThemedBackgroundGlobal } from 'pages/styled'
import TransactionsTable from '../../components/TransactionsTable'
import { useActiveNetworkVersion } from 'state/application/hooks'
import styled from 'styled-components'
import { ChartCard } from 'components/Card'
import { DailyInfoElement } from 'pages/Home'
import AccountIcon from '../../assets/svg/account-circle.svg'
import LockResetIcon from '../../assets/svg/lock-reset.svg'
import ChronosHourglass from '../../assets/svg/hourglass.svg'
import { networkPrefix } from 'utils/networkPrefix'
import TrophyIcon from '../../assets/svg/trophy.svg'
import RocketIcon from '../../assets/svg/rocket.svg'
import BribesIcon from '../../assets/svg/bribes.svg'
import VotesChart from 'components/VoteChart/alt'
import voteAnalyticsMockData, { getRandomColor, epochsDropdownData, partnerDropdownData } from './utils'
import ExpandIcon from '../../assets/svg/expand.svg'
import InsightIcon from '../../assets/svg/insights.svg'
import PoolTable from 'components/pools/PoolTable'
import { useAllPoolData } from 'state/pools/hooks'
import EpochsTable from 'components/Epochs/EpochsTable'
import InfoIcon from '../../assets/svg/info.svg'
import CloseIcon from '../../assets/svg/close.svg'
import PartnerVoteTable from 'components/Epochs/PartnerVoteTable'

type EpochDTO = {
  title: string
  value: number
}

type PartnerDTO = {
  title: string
  value: number
}

// Update types accordingly

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 18px;
`

const Navigation = styled.div`
  display: flex;
  width: 100%;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  
  `};
`

const DropDown = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 12px 24px;
  width: 330px;
  background: ${({ theme }) => theme.bg1};
  border-radius: 20px;
  transition: border-radius 0.01s ease;
  margin-top: 12px;
  position: relative;
`
const DropDownContentContainer = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: ${({ isExpanded }) => (isExpanded ? '500px' : '0')};
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background: ${({ theme }) => theme.bg1};
  backdrop-filter: blur(20px);
  margin-top: 2px;
`

const EpochOption = styled.div`
  display: flex;
  padding-left: 24px;
  padding: 8px 0 8px 24px;

  &:hover {
    background: ${({ theme }) => theme.gd1};
  }
`

const HeaderContainer = styled(RowBetween)`
  margin-bottom: 16px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
flex-direction: column;
`};
`

const ChartWrapper = styled(ChartCard)`
  width: 100%;
  padding-bottom: 1rem;
`

const ChartTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0 0 2rem;
`

const InfoRow = styled.div`
  display: flex;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
flex-direction: column;
width: 100%;
margin-top: 16px;
`};
`
const InfoRowContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-template-rows: 1fr;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};
`

const EpochInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  max-width: 490px;
  gap: 12px;
  background: #2e2c47;
  border-radius: 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
`

const InfoButton = styled.div<{ isButton?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px
  height: 24px;
  border-radius: 100%;
  cursor: ${({ isButton }) => (isButton ? 'pointer' : 'default')};
`

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px
  height: 24px;
  border-radius: 100%;

  &:hover{
    background: ${({ theme }) => theme.bg0};
  }
`

const RangeContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  justify-content: center;
  margin-top: 16px;
`};
`

const RangeSelectorContainer = styled.div`
  background: ${({ theme }) => theme.bg1};
  border-radius: 20px;
  padding: 19px 24px;
`

const Rangeinput = styled.input<{ value?: number }>`
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 15rem;
  z-index: 1;

  ::-webkit-slider-runnable-track {
    background: transparent;
    height: 4px;
    border-radius: 20px;
  }

  ::-moz-range-track {
    background: transparent;
    height: 4px;
    border-radius: 20px;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -3px;
    background-color: #ffffff;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    z-index: 99;
  }

  ::-moz-range-thumb {
    border: none;
    border-radius: 100%;
    background-color: #ffffff;
    height: 10px;
    width: 10px;
    z-index: 99;
  }
`

const InputWrapper = styled.div`
  background: ${({ theme }) => theme.bg2};
  border-radius: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 2px;
  position: relative;
`

const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `};
`

const TrackBackgroundContainer = styled.div`
  height: 4px;
  position: absolute;
  width: 15rem;
  z-index: 0;
`

const TrackBackground = styled.div<{ elementWidth?: number }>`
  border-radius: 20px;
  height: 100%;
  background: linear-gradient(to right, #3f4ab3, #7a64d0);
  width: ${({ elementWidth }) => elementWidth && `${elementWidth}%`};
`

const PoolsList = styled.div`
  min-width: 250px;
  border-radius: 20px;
  background: ${({ theme }) => theme.bg1};
  padding: 24px;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  width: 50%;
  `};
`

const ListTitle = styled.span`
  font-size: 13px;
  font-weight: 500;
`

const PoolsScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  height: 300px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.bg1};
  }

  ::-webkit-scrollbar-thumb {
    background-color: #908e95;
    border-radius: 5px;
  }
`

const PoolElement = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 4px 0;
`

const PoolDot = styled.div<{ color?: string }>`
  height: 7px;
  width: 7px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
`

const PoolName = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.text1};
`

const ResponsiveChartRow = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  flex-direction: column;
  align-items: center;
  `};
`

export default function VoteAnalyticsPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const theme = useTheme()

  const [activeNetwork] = useActiveNetworkVersion()
  const allPoolData = useAllPoolData()

  const [lockedPercentageHover, setLockedPercentageHover] = useState<number | undefined>()
  const [lockedPercentageLabel, setLockedPercentageLabel] = useState<string | undefined>()
  const [averageLockedLabel, setAverageLockedLabel] = useState<string | undefined>()
  const [lockedHolderLabel, setLockedHolderLabel] = useState<string | undefined>()
  const [voteChartData, setVoteChartData] = useState<any[]>()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isPartnerExpanded, setIsPartnerExpanded] = useState(false)
  const [selectedEpoch, setSelectedEpoch] = useState<EpochDTO | null>(null)
  const [selectedPartner, setSelectedPartner] = useState<PartnerDTO | null>(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const [isEpochInfoOpen, setIsEpochInfoOpen] = useState(false)
  const [isPartnerInfoOpen, setIsPartnerInfoOpen] = useState(false)
  const [selectedEpochRange, setSelectedEpochRange] = useState(0)

  useEffect(() => {
    setLockedPercentageHover(undefined)
  }, [activeNetwork])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 720)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const formatLargeNumber = (number: string) => {
    const formattedNumber = Number(number)
    const suffixes = ['', 'k', 'M', 'B', 'T']
    const suffixIndex = Math.floor(Math.log10(formattedNumber) / 3)
    const scaledNumber = formattedNumber / Math.pow(10, suffixIndex * 3)
    return scaledNumber.toFixed(0)
  }

  const formatWeightPercentage = (voteValue: string) => {
    const formattedValue = parseFloat(voteValue) / 10 ** 18

    return formattedValue.toFixed(2)
  }

  useEffect(() => {
    if (voteAnalyticsMockData) {
      setVoteChartData(
        voteAnalyticsMockData.map((voteData) => ({
          voteWeight: formatLargeNumber(voteData.voteWeight),
          symbol: `${voteData.pair.symbol} ${formatWeightPercentage(voteData.voteWeightPercentage._value)}`,
          barColor: getRandomColor(),
        }))
      )
    }
  }, [voteAnalyticsMockData])

  const handleSliderChange = (event: any) => {
    const inputValue = event.target.value

    setSelectedEpochRange(inputValue)
  }

  const gradientElementWidth = (selectedEpochRange / 15) * 100

  const sortedData = voteChartData?.sort((a, b) => b.voteWeight - a.voteWeight) ?? []

  const getPoolElement = (pool: string) => {
    const parts = pool.split(' ')

    const symbol = parts[0]
    const weightPercentage = parts[1]

    return `${symbol} (${weightPercentage}%)`
  }

  return (
    <PageContainer>
      <ThemedBackgroundGlobal backgroundColor={activeNetwork.bgColor} />
      <ContentContainer>
        <Navigation>
          <StyledInternalLink to={networkPrefix(activeNetwork)}>
            <TYPE.main fontSize="12px" color={theme.textWithOpacity}>{`Home > `}</TYPE.main>
          </StyledInternalLink>
          <TYPE.main fontSize="12px" color={theme.textWithOpacity}>{` Vote Analytics `}</TYPE.main>
        </Navigation>

        <HeaderContainer>
          <Column>
            <TYPE.subHeader fontSize="25px">Epoch</TYPE.subHeader>
            <TYPE.label fontSize="14px" color={theme.textWithOpacity}>
              Choose Epoch Number
            </TYPE.label>
            <DropDown onClick={() => setIsExpanded((prev) => !prev)} isExpanded={isExpanded}>
              <TYPE.label fontSize="14px">{selectedEpoch?.title ?? `Select Epoch`}</TYPE.label>
              <img
                src={ExpandIcon}
                alt="expand"
                height={20}
                width={20}
                style={{ transform: isExpanded ? 'rotate(180deg)' : 'none', transition: 'transform 0.2s ease-in-out' }}
              />
              <DropDownContentContainer isExpanded={isExpanded}>
                {epochsDropdownData.map((option) => (
                  <EpochOption key={option.value} onClick={() => setSelectedEpoch(option)}>
                    <TYPE.label fontSize="14px">{option.title}</TYPE.label>
                  </EpochOption>
                ))}
              </DropDownContentContainer>
            </DropDown>
          </Column>
          <InfoRow>
            <DailyInfoElement title="Total Voter Rewards" value="24957.81" icon={RocketIcon} />
            <DailyInfoElement title="Highest Voted Pool" value="CHR / ETH (78.45%)" icon={TrophyIcon} />
          </InfoRow>
        </HeaderContainer>

        <ChartWrapper>
          <ChartTitleContainer>
            <TYPE.label fontSize="25px" fontWeight={400}>
              Vote Analytics
            </TYPE.label>
          </ChartTitleContainer>

          <ResponsiveChartRow>
            <VotesChart data={voteChartData ?? []} height={220} minHeight={332} />
            <PoolsList>
              <ListTitle>Pool Name</ListTitle>
              <PoolsScrollableContainer>
                {sortedData.map((pool) => (
                  <PoolElement key={pool.barColor}>
                    <PoolDot color={pool.barColor} />
                    <PoolName>{getPoolElement(pool.symbol)}</PoolName>
                  </PoolElement>
                ))}
              </PoolsScrollableContainer>
            </PoolsList>
          </ResponsiveChartRow>
        </ChartWrapper>

        <InfoRowContainer>
          <DailyInfoElement title="Internal Bribes(Fees)" value="24957.81" icon={InsightIcon} />
          <DailyInfoElement title="External Bribes" value="7551.09" icon={BribesIcon} />
          <DailyInfoElement title="Total Voter Rewards" value="24957.81" icon={RocketIcon} />
        </InfoRowContainer>

        <TableRowBetween>
          <TYPE.subHeader fontSize="25px">Epoch Data</TYPE.subHeader>
          {!isEpochInfoOpen && (
            <InfoButton onClick={() => setIsEpochInfoOpen((prev) => !prev)} isButton>
              <img src={InfoIcon} height={17} width={17} alt="info" />
            </InfoButton>
          )}
          {isEpochInfoOpen && (
            <EpochInfoContainer>
              <RowBetween>
                <InfoButton>
                  <img src={InfoIcon} height={17} width={17} alt="info" />
                </InfoButton>

                <CloseButton onClick={() => setIsEpochInfoOpen((prev) => !prev)}>
                  <img src={CloseIcon} height={17} width={17} alt="info" />
                </CloseButton>
              </RowBetween>
              <TYPE.label fontSize="13px" fontWeight={400}>
                Projected APR is based on current votes and upcoming epoch&apos;s CHR emissions at current prices.
              </TYPE.label>
            </EpochInfoContainer>
          )}
        </TableRowBetween>
        <EpochsTable poolDatas={Object.values(allPoolData)} />

        <HeaderRow>
          <ColumnStart>
            <TYPE.label fontSize="25px" fontWeight={400}>
              Partner Data
            </TYPE.label>
            <TYPE.label fontSize="14px" fontWeight={400}>
              Choose partner Name
            </TYPE.label>
            <DropDown onClick={() => setIsPartnerExpanded((prev) => !prev)} isExpanded={isPartnerExpanded}>
              <TYPE.label fontSize="14px">{selectedPartner?.title ?? `Select Name`}</TYPE.label>
              <img
                src={ExpandIcon}
                alt="expand"
                height={20}
                width={20}
                style={{
                  transform: isPartnerExpanded ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.2s ease-in-out',
                }}
              />
              <DropDownContentContainer isExpanded={isPartnerExpanded}>
                {partnerDropdownData.map((option) => (
                  <EpochOption key={option.value} onClick={() => setSelectedPartner(option)}>
                    <TYPE.label fontSize="14px">{option.title}</TYPE.label>
                  </EpochOption>
                ))}
              </DropDownContentContainer>
            </DropDown>
          </ColumnStart>
          <RangeContainer>
            {!isPartnerInfoOpen && !isMobile && (
              <InfoButton onClick={() => setIsPartnerInfoOpen((prev) => !prev)} isButton>
                <img src={InfoIcon} height={17} width={17} alt="info" />
              </InfoButton>
            )}
            {isPartnerInfoOpen && (
              <EpochInfoContainer>
                <RowBetween>
                  <InfoButton>
                    <img src={InfoIcon} height={17} width={17} alt="info" />
                  </InfoButton>

                  <CloseButton onClick={() => setIsPartnerInfoOpen((prev) => !prev)}>
                    <img src={CloseIcon} height={17} width={17} alt="info" />
                  </CloseButton>
                </RowBetween>
                <TYPE.label fontSize="13px" fontWeight={400}>
                  If you have not bribed for the pool you voted for, kindly disregard that entry. Only bribes sent from
                  the same veNFT address will be counted for correct bribe ROI calculation.
                </TYPE.label>
              </EpochInfoContainer>
            )}
            <RangeSelectorContainer>
              <ColumnStart>
                <TYPE.label fontSize="14px" fontWeight={500}>
                  Choose Epoch Range
                </TYPE.label>
              </ColumnStart>

              <InputWrapper>
                <TrackBackgroundContainer>
                  <TrackBackground elementWidth={gradientElementWidth} />
                </TrackBackgroundContainer>
                <Rangeinput
                  type="range"
                  className="z-1 relative min-w-full opacity-0"
                  value={selectedEpochRange}
                  min={0}
                  max={15}
                  onChange={handleSliderChange}
                  onClick={(e) => e.stopPropagation()}
                />
              </InputWrapper>
              <RowBetween>
                <TYPE.label fontSize="10px" fontWeight={400} color={theme.textWithOpacity}>
                  0
                </TYPE.label>
                <TYPE.label fontSize="10px" fontWeight={400}>
                  {selectedEpochRange}
                </TYPE.label>
                <TYPE.label fontSize="10px" fontWeight={400} color={theme.textWithOpacity}>
                  15
                </TYPE.label>
              </RowBetween>
            </RangeSelectorContainer>
          </RangeContainer>
        </HeaderRow>

        <PartnerVoteTable poolDatas={Object.values(allPoolData)} />
      </ContentContainer>
    </PageContainer>
  )
}
