// import { useUpdatePoolData, useAllGaugesData, useAddGaugesKeys } from './hooks'
import { useEffect, useMemo } from 'react'
import { useTopPoolAddresses } from 'data/pools/topPools'
import { usePoolDatas } from 'data/pools/poolData'
import { POOL_HIDE } from '../../constants'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { useTopGaugesAddresses } from 'data/gauges/topGauges'
import { notEmpty } from 'utils'
import { useAllPoolData } from 'state/pools/hooks'
import { useGetAllClPairRewards, useGetAllPairRewards } from 'hooks/useRewardAPIV3'

export default function Updater(): null {
  // updaters
  // const [currentNetwork] = useActiveNetworkVersion()
  // const updatePoolData = useUpdatePoolData()
  // const addGaugesKeys = useAddGaugesKeys()

  const rewardsCl = useGetAllClPairRewards()
  const rewards = useGetAllPairRewards()

  useEffect(() => {
    if (rewards) {
      console.log('Updaterrewards', rewards)
    }
  }, [rewardsCl, rewards])

  // // data
  // // const allPoolData = useAllPoolData()
  // const allPoolData = useAllPoolData()
  // const poolDatas = useMemo(() => {
  //   return Object.values(allPoolData)
  //     .map((p) => p.data)
  //     .filter(notEmpty)
  // }, [allPoolData])
  // const poolAddresses = useMemo(() => {
  //   return Object.keys(allPoolData)
  // }, [allPoolData])

  // // const { loading, error, pooladdresses } = useTopPoolAddresses()
  // const { loading, error, addresses } = useTopGaugesAddresses(poolAddresses)

  // // add top pools on first load
  // useEffect(() => {
  //   if (addresses && !error && !loading) {
  //     addGaugesKeys(addresses)
  //   }
  // }, [addGaugesKeys, addresses, error, loading])

  // load data for pools we need to hide
  // useEffect(() => {
  //   addPoolKeys(POOL_HIDE[currentNetwork.id])
  // }, [addPoolKeys, currentNetwork.id])

  // detect for which addresses we havent loaded pool data yet
  // const unfetchedPoolAddresses = useMemo(() => {
  //   return Object.keys(allPoolData).reduce((accum: string[], key) => {
  //     const poolData = allPoolData[key]
  //     if (!poolData.data || !poolData.lastUpdated) {
  //       accum.push(key)
  //     }
  //     return accum
  //   }, [])
  // }, [allPoolData])

  // update unloaded pool entries with fetched data
  // const { error: poolDataError, loading: poolDataLoading, data: poolDatas } = usePoolDatas(unfetchedPoolAddresses)

  // useEffect(() => {
  //   if (poolDatas && !poolDataError && !poolDataLoading) {
  //     updatePoolData(Object.values(poolDatas))
  //   }
  // }, [poolDataError, poolDataLoading, poolDatas, updatePoolData])

  return null
}
