import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { TYPE } from 'theme'
import { DarkGreyCard, GreyBadge } from 'components/Card'
import Loader, { LoadingRows } from 'components/Loader'
import { AutoColumn, ColumnCenter, ColumnStart } from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import { formatDollarAmount } from 'utils/numbers'
import { PoolData } from 'state/pools/reducer'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { feeTierPercent } from 'utils'
import { Label, ClickableText, SmallLabel } from 'components/Text'
import { PageButtons, Arrow, Break } from 'components/shared'
import { POOL_HIDE } from '../../constants/index'
import useTheme from 'hooks/useTheme'
import { networkPrefix } from 'utils/networkPrefix'
import { useActiveNetworkVersion } from 'state/application/hooks'
import ExpandIcon from '../../assets/svg/expand.svg'
import { PairInfoV3 } from 'hooks/usePairAPIV3'
import { BigNumber, FixedNumber } from 'ethers'
import { useGetChronosTokens } from 'state/global/hooks'
import { CHR_ADDRESS } from 'constants/Constants'

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
  padding: 0;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  padding: 16px 1rem;

  grid-template-columns: 20px 3.5fr repeat(3, 1fr);

  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 1.5fr repeat(2, 1fr);
    & :nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 20px 1.5fr repeat(1, 1fr);
    & :nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 2.5fr repeat(1, 1fr);
    > *:nth-child(1) {
      display: none;
    }
  }
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    cursor: pointer;
    background: #36314e;
  }
`

const PaginationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`

const PageNumberButtons = styled.div`
  display: flex;
  gap: 5px;
`

const PageButton = styled.button<{ active?: boolean }>`
  cursor: pointer;
  border: none;
  background-color: #2b2940;
  color: ${({ active, theme }) => (active ? theme.white : 'rgba(255, 255, 255, 0.3)')};
  padding: 6px 15px;
  border-radius: 100px;
  font-size: 15px;
  outline: none;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const PaginationEllipsis = styled.span`
  cursor: default;
  color: rgba(255, 255, 255, 0.3);
  border-radius: 100px;
  padding: 6px 15px;
  font-size: 15px;
  border: none;
  background-color: #2b2940;
`

const ExpandButton = styled.div``

const StyledRowBetween = styled(RowBetween)`
  align-items: center;
`
const NameContainer = styled.div`
  background-color: #39344f;
  border-radius: 20px;
  padding: 2px 10px;
  max-width: 110px;
`

const StyledColumnStart = styled(ColumnStart)`
  background-color: #39344f;
  border-radius: 10px;
  padding: 5px 10px;
  width: 110px;
`

const StyledDetails = styled(ColumnStart)`
  background-color: #39344f;
  border-radius: 10px;
  padding: 10px;
`

const StyledSmallType = styled(TYPE.small)`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledColumnCenter = styled(ColumnCenter)<{ isExpanded?: boolean }>`
  border-radius: 15px;
  background: #2c283d;
  background-blend-mode: overlay;
  padding: 14px 12px;
  gap: ${({ isExpanded }) => (isExpanded ? '15px' : '0')};

  transition: gap 0.3s ease-in-out;
`

const ExpandingContent = styled(RowBetween)<{ isExpanded?: boolean }>`
  max-height: ${({ isExpanded }) => (isExpanded ? '500px' : '0')};
  border-radius: 0;
  gap: 8px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`

const SORT_FIELD = {
  feeTier: 'feeTier',
  volumeUSD: 'volumeUSD',
  tvlUSD: 'tvlUSD',
  volumeUSDWeek: 'volumeUSDWeek',
}

const DataRow = ({
  chrPrice,
  apr,
  poolData,
  index,
}: {
  chrPrice: number
  apr: number
  poolData: {
    data: PoolData | undefined
    dysonData: PairInfoV3 | undefined
    lastUpdated: number | undefined
  }
  index: number
}) => {
  // const [activeNetwork] = useActiveNetworkVersion()

  const formattedIndex = (index + 1).toString().padStart(2, '0')
  if (!poolData || !poolData.data || !poolData.dysonData) {
    return null
  }
  return (
    <LinkWrapper to={'/gauges/' + poolData?.dysonData?.pair_address}>
      <ResponsiveGrid>
        <Label fontWeight={400}>{formattedIndex}</Label>
        <Label fontWeight={400}>
          <RowFixed>
            <DoubleCurrencyLogo
              address0={poolData.data.token0.address}
              address1={poolData.data.token1.address}
              size={36}
              margin
            />
            <TYPE.label ml="8px">
              {poolData.data.token0.symbol}/{poolData.data.token1.symbol}
            </TYPE.label>
            <GreyBadge ml="5px" fontSize="14px">
              +{feeTierPercent(poolData.data.feeTier)}
            </GreyBadge>
          </RowFixed>
        </Label>
        <Label end={1} fontWeight={400}>
          {apr.toFixed(2)}%
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(
            FixedNumber.fromValue(BigNumber.from(poolData.dysonData.gauge_total_weight), 18).toUnsafeFloat() * chrPrice
          )}
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(FixedNumber.fromValue(BigNumber.from(0), 18).toUnsafeFloat())}
        </Label>
      </ResponsiveGrid>
    </LinkWrapper>
  )
}

const MobileDataRow = ({
  chrPrice,
  apr,
  poolData,
  index,
}: {
  chrPrice: number
  apr: number
  poolData: {
    data: PoolData | undefined
    dysonData: PairInfoV3 | undefined
    lastUpdated: number | undefined
  }
  index: number
}) => {
  const theme = useTheme()
  // const [activeNetwork] = useActiveNetworkVersion()
  const formattedIndex = (index + 1).toString().padStart(2, '0')
  const [isExpanded, setIsExpanded] = useState(false)
  if (!poolData || !poolData.data || !poolData.dysonData) {
    return null
  }
  return (
    <StyledColumnCenter onClick={() => setIsExpanded((prev) => !prev)} isExpanded={isExpanded}>
      <StyledRowBetween>
        <RowFixed>
          <TYPE.small mr="5px">{formattedIndex}</TYPE.small>
          <LinkWrapper to={'/gauges/' + poolData.dysonData.pair_address}>
            <RowFixed>
              <DoubleCurrencyLogo
                address0={poolData.data.token0.address}
                address1={poolData.data.token1.address}
                size={36}
                margin
              />
              <ColumnStart>
                <Label>
                  {poolData.data.token0.symbol}/{poolData.data.token1.symbol}
                </Label>
                <GreyBadge fontSize="14px">+{feeTierPercent(poolData.data.feeTier)}</GreyBadge>
              </ColumnStart>
            </RowFixed>
          </LinkWrapper>
        </RowFixed>
        <RowFixed>
          <StyledColumnStart>
            <Label>APR</Label>
            <Label end={1} fontWeight={500} isGreen>
              {apr.toFixed(2)}%
            </Label>
          </StyledColumnStart>
          <ExpandButton>
            <img
              src={ExpandIcon}
              alt="expand"
              height={20}
              width={20}
              style={{ transform: isExpanded ? 'rotate(180deg)' : 'none', transition: 'transform 0.2s ease-in-out' }}
            />
          </ExpandButton>
        </RowFixed>
      </StyledRowBetween>
      <ExpandingContent isExpanded={isExpanded}>
        <StyledDetails>
          <SmallLabel fontWeight={300} isGreen>
            Total Votes
          </SmallLabel>
          <SmallLabel end={1} fontWeight={400}>
            {formatDollarAmount(
              FixedNumber.fromValue(BigNumber.from(poolData.dysonData.gauge_total_weight), 18).toUnsafeFloat() *
                chrPrice
            )}
          </SmallLabel>
        </StyledDetails>

        <StyledDetails>
          <SmallLabel fontWeight={300}>Rewards</SmallLabel>
          <SmallLabel end={1} fontWeight={400}>
            {formatDollarAmount(FixedNumber.fromValue(BigNumber.from(0), 18).toUnsafeFloat())}
          </SmallLabel>
        </StyledDetails>
      </ExpandingContent>
    </StyledColumnCenter>
  )
}

const MAX_ITEMS = 10

export default function GaugeTable({
  poolDatas,
  maxItems = MAX_ITEMS,
}: {
  poolDatas: {
    data: PoolData | undefined
    dysonData: PairInfoV3 | undefined
    lastUpdated: number | undefined
  }[]
  maxItems?: number
}) {
  const [currentNetwork] = useActiveNetworkVersion()

  const chronosTokens = useGetChronosTokens()

  const chrPrice = useMemo(() => {
    if (!chronosTokens) {
      return 0
    }
    const chr_data = chronosTokens[CHR_ADDRESS]
    if (!chr_data) {
      return 0
    }
    return chr_data.price_quote
  }, [chronosTokens])

  // theming
  const theme = useTheme()

  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.tvlUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  useEffect(() => {
    let extraPages = 1
    if (poolDatas.length % maxItems === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(poolDatas.length / maxItems) + extraPages)
  }, [maxItems, poolDatas])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 720)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getPoolApr = useCallback(
    (pool: {
      data: PoolData | undefined
      dysonData: PairInfoV3 | undefined
      lastUpdated: number | undefined
    }): number => {
      // Formula:
      // 1. TVL = (reserve0 * price0) + (reserve1 * price1)
      // 2. LP100 = 100/tvl/totalSupply
      // 3. APR = LP100 * emissionsPerSecond * 31536000 * chrPrice / totaWeight
      // 4. MaxAPR = APR * 3
      // reserve0 = pool.dysonData.reserve0
      // reserve1 = pool.dysonData.reserve1
      // emissionsPerSecond = pool.dysonData.emissions
      // totalWeight = pool.dysonData.gauge_total_weight
      if (!pool || !pool.data || !pool.dysonData || !chronosTokens) {
        return 0
      }
      const chr_data = chronosTokens[CHR_ADDRESS]
      const token0_data = chronosTokens[pool.dysonData.token0]
      const token1_data = chronosTokens[pool.dysonData.token1]
      if (!chr_data || !token0_data || !token1_data) {
        return 0
      }
      // const chrPrice = chr_data.price_quote
      console.log('chrPrice', chrPrice)

      const price0 = token0_data.price_quote
      const price1 = token1_data.price_quote

      const reserve0 = FixedNumber.fromValue(
        BigNumber.from(pool.dysonData.reserve0),
        token0_data.decimals
      ).toUnsafeFloat()
      const reserve1 = FixedNumber.fromValue(
        BigNumber.from(pool.dysonData.reserve1),
        token1_data.decimals
      ).toUnsafeFloat()
      const emissionsPerSecond = FixedNumber.fromValue(BigNumber.from(pool.dysonData.emissions), 18).toUnsafeFloat()
      const totalWeight = FixedNumber.fromValue(BigNumber.from(pool.dysonData.gauge_total_weight), 18).toUnsafeFloat()
      const totalSupply = FixedNumber.fromValue(BigNumber.from(pool.dysonData.total_supply), 18).toUnsafeFloat()
      // const chrPrice = BigNumber.from(pool.dysonData.chrPrice)

      const tvl = reserve0 * price0 + reserve1 * price1

      // console.log('tvl', tvl.toString())
      const lp100 = 100 / tvl / totalSupply
      // console.log('lp100', lp100.toString())
      const apr = (lp100 * emissionsPerSecond * 31536000 * chrPrice) / totalWeight
      // console.log('apr', apr.toString())
      const maxApr = apr * 3
      // console.log('maxApr', maxApr.toString())
      return apr
    },
    [chronosTokens]
  )

  const getGaugeApr = useCallback(
    (pool: {
      data: PoolData | undefined
      dysonData: PairInfoV3 | undefined
      lastUpdated: number | undefined
    }): number => {
      return 0
      // Formula:
      // 1. totalVotesOnGauge =
      // 2. LP100 = 100/tvl/totalSupply
      // 3. APR = LP100 * emissionsPerSecond * 31536000 * chrPrice / totaWeight
      // 4. MaxAPR = APR * 3
      //   let totalVotesOnGaugeBigDecimal = new BigDecimal(reward.totalVotesOnGauge, 18)
      //   .mulNumber(chrTokenPrice)
      //   .toRoundedFloat();
      // // if (token0Symbol === 'FUSDC') {
      // //   console.log('Rewards');
      // //   console.log(usdValueExternal);
      // //   console.log(usdValueInternal);
      // //   console.log(usdValueExternal.add(usdValueInternal).mulNumber(100 * 52.1));
      // //   console.log(totalVotesOnGaugeBigDecimal);
      // //   console.log("paco");
      // //   console.log(new BigDecimal(10n, 6).div(new BigDecimal(1000n, 18)));
      // // }
      // return (
      //   (usdValueExternal.add(usdValueInternal).toRoundedFloat() * 100 * 52.1) /
      //   totalVotesOnGaugeBigDecimal
      // );
    },
    [chronosTokens]
  )

  const sortedPools = useMemo(() => {
    return poolDatas
      ? poolDatas
          .sort((a, b) => {
            if (a.data && b.data) {
              return a.data[sortField as keyof PoolData] > b.data[sortField as keyof PoolData]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [maxItems, page, poolDatas, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage >= 1 && newPage <= maxPage) {
        setPage(newPage)
      }
    },
    [maxPage]
  )

  const pageNumbers = useMemo(() => {
    const arr = []
    for (let i = 1; i <= maxPage; i++) {
      arr.push(i)
    }
    return arr
  }, [maxPage])

  const isPrevDisabled = page === 1
  const isNextDisabled = page === maxPage

  if (!poolDatas) {
    return <Loader />
  }

  return (
    <Wrapper>
      {sortedPools.length > 0 ? (
        <AutoColumn>
          {!isMobile && (
            <ResponsiveGrid>
              <Label color={theme.text2}>#</Label>
              <ClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.feeTier)}>
                Name {arrow(SORT_FIELD.feeTier)}
              </ClickableText>
              <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.tvlUSD)}>
                APR {arrow(SORT_FIELD.tvlUSD)}
              </ClickableText>
              <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.volumeUSD)}>
                Total Votes {arrow(SORT_FIELD.volumeUSD)}
              </ClickableText>
              <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.volumeUSDWeek)}>
                Rewards {arrow(SORT_FIELD.volumeUSDWeek)}
              </ClickableText>
            </ResponsiveGrid>
          )}
          <Break />
          {sortedPools.map((poolData, i) => {
            if (poolData) {
              return (
                <React.Fragment key={i}>
                  {isMobile ? (
                    <MobileDataRow
                      index={(page - 1) * MAX_ITEMS + i}
                      apr={getGaugeApr(poolData)}
                      chrPrice={chrPrice}
                      poolData={poolData}
                    />
                  ) : (
                    <DataRow
                      index={(page - 1) * MAX_ITEMS + i}
                      apr={getGaugeApr(poolData)}
                      chrPrice={chrPrice}
                      poolData={poolData}
                    />
                  )}

                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          <PaginationButtons>
            <PageButton onClick={() => handlePageChange(page - 1)} disabled={isPrevDisabled}>
              ←
            </PageButton>
            <PageNumberButtons>
              {pageNumbers.map((pageNumber, index) => {
                if (pageNumber === 1 || pageNumber === maxPage || (pageNumber >= page - 2 && pageNumber <= page + 2)) {
                  return (
                    <PageButton
                      key={pageNumber}
                      onClick={() => handlePageChange(pageNumber)}
                      active={pageNumber === page}
                    >
                      {pageNumber}
                    </PageButton>
                  )
                } else if (pageNumber === page - 5 || pageNumber === page + 5) {
                  return <PaginationEllipsis key={index}>...</PaginationEllipsis>
                }
                return null
              })}
            </PageNumberButtons>
            <PageButton onClick={() => handlePageChange(page + 1)} disabled={isNextDisabled}>
              →
            </PageButton>
          </PaginationButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
