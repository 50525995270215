import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { TYPE } from 'theme'
import { DarkGreyCard, GreyBadge } from 'components/Card'
import Loader, { LoadingRows } from 'components/Loader'
import { AutoColumn, ColumnCenter, ColumnStart } from 'components/Column'
import { ResponsiveRow, RowBetween, RowFixed } from 'components/Row'
import { formatDollarAmount } from 'utils/numbers'
import { PoolData } from 'state/pools/reducer'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { feeTierPercent } from 'utils'
import { Label, ClickableText, SmallLabel } from 'components/Text'
import { PageButtons, Arrow, Break } from 'components/shared'
import { POOL_HIDE } from '../../constants/index'
import useTheme from 'hooks/useTheme'
import { networkPrefix } from 'utils/networkPrefix'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { PairInfoV3 } from 'hooks/usePairAPIV3'
import ExpandIcon from '../../assets/svg/expand.svg'

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
  padding: 0;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 1rem;
  grid-template-columns: 50px repeat(7, 1fr);

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(6, 1fr) 1.5fr 1.5fr;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(4, 1fr) repeat(2, 1.5fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr) repeat(3, 1.5fr) 1.5fr;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr) repeat(2, 1.5fr) 2.5fr 1fr;
  }
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    cursor: pointer;
    background: #36314e;
  }
`

const PaginationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`

const PageNumberButtons = styled.div`
  display: flex;
  gap: 5px;
`

const PageButton = styled.button<{ active?: boolean }>`
  cursor: pointer;
  border: none;
  background-color: #2b2940;
  color: ${({ active, theme }) => (active ? theme.white : 'rgba(255, 255, 255, 0.3)')};
  padding: 6px 15px;
  border-radius: 100px;
  font-size: 15px;
  outline: none;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const PaginationEllipsis = styled.span`
  cursor: default;
  color: rgba(255, 255, 255, 0.3);
  border-radius: 100px;
  padding: 6px 15px;
  font-size: 15px;
  border: none;
  background-color: #2b2940;
`

const ExpandButton = styled.div``

const StyledRowBetween = styled(RowBetween)`
  align-items: center;
`
const NameContainer = styled.div`
  background-color: #39344f;
  border-radius: 20px;
  padding: 2px 10px;
  max-width: 110px;
`

const StyledColumnStart = styled(ColumnStart)`
  background-color: #39344f;
  border-radius: 10px;
  padding: 5px 10px;
  width: 110px;
`

const StyledDetails = styled(ColumnStart)`
  background-color: #39344f;
  border-radius: 10px;
  padding: 10px;
`

const StyledSmallType = styled(TYPE.small)`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledColumnCenter = styled(ColumnCenter)<{ isExpanded?: boolean }>`
  border-radius: 15px;
  background: #2c283d;
  background-blend-mode: overlay;
  padding: 14px 12px;
  gap: ${({ isExpanded }) => (isExpanded ? '15px' : '0')};

  transition: gap 0.3s ease-in-out;
`

const ExpandingContent = styled(RowBetween)<{ isExpanded?: boolean }>`
  max-height: ${({ isExpanded }) => (isExpanded ? '500px' : '0')};
  border-radius: 0;
  gap: 8px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`

const EpochColumnContainer = styled.div`
  max-width: 50px;
`

const SORT_FIELD = {
  feeTier: 'feeTier',
  volumeUSD: 'volumeUSD',
  tvlUSD: 'tvlUSD',
  volumeUSDWeek: 'volumeUSDWeek',
}

const DataRow = ({
  poolData,
  index,
}: {
  poolData: {
    data: PoolData | undefined
    dysonData: PairInfoV3 | undefined
    lastUpdated: number | undefined
  }
  index: number
}) => {
  const [activeNetwork] = useActiveNetworkVersion()
  const theme = useTheme()

  const formattedIndex = (index + 1).toString().padStart(2, '0')

  if (!poolData.data || !poolData.dysonData) {
    return null
  }
  return (
    <LinkWrapper to={networkPrefix(activeNetwork) + 'pools/' + poolData?.dysonData?.dysonPool}>
      <ResponsiveGrid>
        <EpochColumnContainer>
          <Label fontWeight={400}>{formattedIndex}</Label>
        </EpochColumnContainer>

        <Label fontWeight={400}>
          <RowFixed>
            <DoubleCurrencyLogo
              address0={poolData.data?.token0.address}
              address1={poolData.data?.token1.address}
              size={20}
            />
            <TYPE.label fontSize="12px">
              {poolData.data?.token0.symbol}/{poolData.data?.token1.symbol}
            </TYPE.label>
            <TYPE.label fontSize="12px" color={theme.textWithOpacity}>
              ({feeTierPercent(poolData.data?.feeTier || 0)})
            </TYPE.label>
          </RowFixed>
        </Label>
        <Label fontWeight={400}>
          {formatDollarAmount(poolData.data?.tvlUSD)}
          {/* Replace with Projected APR value  */}
        </Label>
        <Label fontWeight={400}>
          {formatDollarAmount(poolData.data?.tvlUSD)}
          {/* Replace with Voted Weight value  */}
        </Label>
        <Label fontWeight={400}>
          {formatDollarAmount(poolData.data?.tvlUSD)}
          {/* Replace with Total Vote % value  */}
        </Label>
        <Label fontWeight={400}>
          {formatDollarAmount(poolData.data?.tvlUSD)}
          {/* Replace with Fees value  */}
        </Label>
        <Label fontWeight={400}>
          {formatDollarAmount(poolData.data?.tvlUSD)}
          {/* Replace with Bribes value  */}
        </Label>
        <Label fontWeight={400}>
          {formatDollarAmount(poolData.data?.tvlUSD)}
          {/* Replace with Fees + Bribes value  */}
        </Label>
      </ResponsiveGrid>
    </LinkWrapper>
  )
}

const MobileDataRow = ({
  poolData,
  index,
}: {
  poolData: {
    data: PoolData | undefined
    dysonData: PairInfoV3 | undefined
    lastUpdated: number | undefined
  }
  index: number
}) => {
  const theme = useTheme()
  const [activeNetwork] = useActiveNetworkVersion()

  const formattedIndex = (index + 1).toString().padStart(2, '0')
  const [isExpanded, setIsExpanded] = useState(false)
  if (!poolData.data || !poolData.dysonData) {
    return null
  }
  return (
    <StyledColumnCenter onClick={() => setIsExpanded((prev) => !prev)} isExpanded={isExpanded}>
      <StyledRowBetween>
        <RowFixed>
          <TYPE.small mr="5px">{formattedIndex}</TYPE.small>
          <LinkWrapper to={networkPrefix(activeNetwork) + 'pools/' + poolData.dysonData.clPool}>
            <RowFixed>
              <DoubleCurrencyLogo
                address0={poolData.data.token0.address}
                address1={poolData.data.token1.address}
                size={36}
                margin
              />
              <ColumnStart>
                <Label>
                  {poolData.data.token0.symbol}/{poolData.data.token1.symbol}
                </Label>
                <GreyBadge fontSize="14px">+{feeTierPercent(poolData.data.feeTier)}</GreyBadge>
              </ColumnStart>
            </RowFixed>
          </LinkWrapper>
        </RowFixed>
        <RowFixed>
          <StyledColumnStart>
            <Label>TVL</Label>
            <Label end={1} fontWeight={500} isGreen>
              {formatDollarAmount(poolData.data.tvlUSD)}
            </Label>
          </StyledColumnStart>
          <ExpandButton>
            <img
              src={ExpandIcon}
              alt="expand"
              height={20}
              width={20}
              style={{ transform: isExpanded ? 'rotate(180deg)' : 'none', transition: 'transform 0.2s ease-in-out' }}
            />
          </ExpandButton>
        </RowFixed>
      </StyledRowBetween>
      <ExpandingContent isExpanded={isExpanded}>
        <StyledDetails>
          <SmallLabel fontWeight={300} isGreen>
            24h Volume
          </SmallLabel>
          <SmallLabel end={1} fontWeight={400}>
            {formatDollarAmount(poolData.data.volumeUSD)}
          </SmallLabel>
        </StyledDetails>

        <StyledDetails>
          <SmallLabel fontWeight={300}>7d Volume</SmallLabel>
          <SmallLabel end={1} fontWeight={400}>
            {formatDollarAmount(poolData.data.volumeUSDWeek)}
          </SmallLabel>
        </StyledDetails>
      </ExpandingContent>
    </StyledColumnCenter>
  )
}

const MAX_ITEMS = 10

export default function EpochsTable({
  poolDatas,
  maxItems = MAX_ITEMS,
}: {
  poolDatas: {
    data: PoolData | undefined
    dysonData: PairInfoV3 | undefined
    lastUpdated: number | undefined
  }[]
  maxItems?: number
}) {
  const [currentNetwork] = useActiveNetworkVersion()

  // theming
  const theme = useTheme()

  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.tvlUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  useEffect(() => {
    let extraPages = 1
    if (poolDatas.length % maxItems === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(poolDatas.length / maxItems) + extraPages)
  }, [maxItems, poolDatas])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 720)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const sortedPools = useMemo(() => {
    return poolDatas
      ? poolDatas
          // .filter((x) => !!x && !POOL_HIDE[currentNetwork.id].includes(x.dysonData?.pair_address))
          .sort((a, b) => {
            if (a.data && b.data) {
              return a.data[sortField as keyof PoolData] > b.data[sortField as keyof PoolData]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [currentNetwork.id, maxItems, page, poolDatas, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage >= 1 && newPage <= maxPage) {
        setPage(newPage)
      }
    },
    [maxPage]
  )

  const pageNumbers = useMemo(() => {
    const arr = []
    for (let i = 1; i <= maxPage; i++) {
      arr.push(i)
    }
    return arr
  }, [maxPage])

  const isPrevDisabled = page === 1
  const isNextDisabled = page === maxPage

  if (!poolDatas) {
    return <Loader />
  }

  return (
    <Wrapper>
      {sortedPools.length > 0 ? (
        <AutoColumn>
          {!isMobile && (
            <ResponsiveGrid>
              <EpochColumnContainer>
                <Label color={theme.textWithOpacity} fontSize="12px">
                  Epoch
                </Label>
              </EpochColumnContainer>
              <ClickableText color={theme.textWithOpacity} onClick={() => handleSort(SORT_FIELD.feeTier)}>
                Pool Name {arrow(SORT_FIELD.feeTier)}
              </ClickableText>
              <ClickableText color={theme.textWithOpacity} onClick={() => handleSort(SORT_FIELD.feeTier)}>
                Projected APR {arrow(SORT_FIELD.feeTier)}
              </ClickableText>
              <ClickableText color={theme.textWithOpacity} onClick={() => handleSort(SORT_FIELD.tvlUSD)}>
                Voted Weight {arrow(SORT_FIELD.tvlUSD)}
              </ClickableText>
              <ClickableText color={theme.textWithOpacity} onClick={() => handleSort(SORT_FIELD.volumeUSD)}>
                Total Vote % {arrow(SORT_FIELD.volumeUSD)}
              </ClickableText>
              <ClickableText color={theme.textWithOpacity} onClick={() => handleSort(SORT_FIELD.volumeUSDWeek)}>
                Fees {arrow(SORT_FIELD.volumeUSDWeek)}
              </ClickableText>
              <ClickableText color={theme.textWithOpacity} onClick={() => handleSort(SORT_FIELD.feeTier)}>
                Bribes {arrow(SORT_FIELD.feeTier)}
              </ClickableText>
              <ClickableText color={theme.textWithOpacity} onClick={() => handleSort(SORT_FIELD.feeTier)}>
                Fees + Bribes {arrow(SORT_FIELD.feeTier)}
              </ClickableText>
            </ResponsiveGrid>
          )}
          <Break />
          {sortedPools.map((poolData, i) => {
            if (poolData && poolData.data && poolData.dysonData) {
              return (
                <React.Fragment key={i}>
                  {isMobile ? (
                    <MobileDataRow index={(page - 1) * MAX_ITEMS + i} poolData={poolData} />
                  ) : (
                    <DataRow index={(page - 1) * MAX_ITEMS + i} poolData={poolData} />
                  )}

                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          <PaginationButtons>
            <PageButton onClick={() => handlePageChange(page - 1)} disabled={isPrevDisabled}>
              ←
            </PageButton>
            <PageNumberButtons>
              {pageNumbers.map((pageNumber, index) => {
                if (pageNumber === 1 || pageNumber === maxPage || (pageNumber >= page - 2 && pageNumber <= page + 2)) {
                  return (
                    <PageButton
                      key={pageNumber}
                      onClick={() => handlePageChange(pageNumber)}
                      active={pageNumber === page}
                    >
                      {pageNumber}
                    </PageButton>
                  )
                } else if (pageNumber === page - 5 || pageNumber === page + 5) {
                  return <PaginationEllipsis key={index}>...</PaginationEllipsis>
                }
                return null
              })}
            </PageNumberButtons>
            <PageButton onClick={() => handlePageChange(page + 1)} disabled={isNextDisabled}>
              →
            </PageButton>
          </PaginationButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
