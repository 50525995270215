import { createAction } from '@reduxjs/toolkit'

// // protocol wide info
// export const updateGaugesData = createAction<{ pools: PoolData[]; networkId: SupportedNetwork }>(
//   'gauges/updateGaugesData'
// )

// // add pool address to byAddress
// export const addGaugesKeys = createAction<{ poolAddresses: string[]; networkId: SupportedNetwork }>(
//   'gauges/addGaugesKeys'
// )

// export const updateGaugesChartData = createAction<{
//   poolAddress: string
//   chartData: PoolChartEntry[]
//   networkId: SupportedNetwork
// }>('gauges/updateGaugesChartData')

// export const updateGaugesTransactions = createAction<{
//   poolAddress: string
//   transactions: Transaction[]
//   networkId: SupportedNetwork
// }>('gauges/updateGaugesTransactions')

// export const updateTickData = createAction<{
//   poolAddress: string
//   tickData:
//     | {
//         ticksProcessed: TickProcessed[]
//         feeTier: string
//         tickSpacing: number
//         activeTickIdx: number
//       }
//     | undefined
//   networkId: SupportedNetwork
// }>('gauges/updateTickData')

export const updateSelectedGauge = createAction<{ poolAddress: string | undefined }>('pool/updateSelectedPool')
