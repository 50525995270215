import { updateTotalVeCHR } from './actions'
import { createReducer } from '@reduxjs/toolkit'

export interface VoterData {
  totalVeCHR: number | undefined
}

const initialState: VoterData = {
  totalVeCHR: undefined,
}

export default createReducer(initialState, (builder) =>
  builder.addCase(updateTotalVeCHR, (state, { payload: { totalVeCHR } }) => {
    state.totalVeCHR = totalVeCHR
  })
)
