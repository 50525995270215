import { Contract } from '@ethersproject/contracts'
import { useMemo } from 'react'
import { MULTICALL_ADDRESSES } from '@uniswap/sdk-core'
import UniswapInterfaceMulticallJson from '@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'
import ERC20_ABI from '../constants/abis/erc20.json'
import { getContract } from '../utils'
// import { useActiveWeb3React } from './index'
import { VOTER_V3_ABI, VOTER_V3_ADDRESS } from 'constants/abis/voterV3'
import { PAIR_API_V3_ADDRESS, PAIR_API_V3_ABI } from 'constants/abis/PairApiV3'
import { useWeb3React } from '@web3-react/core'
import { PairApiV3, RewardApiV3, VoterV3 } from 'types/v3'
import { REWARD_API_V3_ABI, REWARD_API_V3_ADDRESS } from 'constants/abis/RewardApiV3'
import { BaseContract } from 'ethers'
// import { UniswapInterfaceMulticall } from 'types/v3'
// import PairApiV3Json from 'constants/abis/pairApiV3.json'
const { abi: MulticallABI } = UniswapInterfaceMulticallJson

// returns null on errors
export function useContract<T extends unknown = BaseContract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, account, chainId } = useWeb3React()
  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]) as T
}

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESSES, MulticallABI, false) as UniswapInterfaceMulticall
}

export function useVoterV3Contract(): VoterV3 | null {
  return useContract<VoterV3>(VOTER_V3_ADDRESS, VOTER_V3_ABI, false)
}

export function usePairAPIV3Contract(): PairApiV3 | null {
  return useContract<PairApiV3>(PAIR_API_V3_ADDRESS, PAIR_API_V3_ABI, false)
}

export function useRewardAPIV3Contract(): RewardApiV3 | null {
  return useContract<RewardApiV3>(REWARD_API_V3_ADDRESS, REWARD_API_V3_ABI, false)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}
