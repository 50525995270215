import { useEffect, useMemo, useState } from 'react'
import { BigNumber } from 'ethers'
// import { NEVER_RELOAD, useSingleCallResult, useSingleContractMultipleData } from '../state/multicall/hooks'
import { usePairAPIV3Contract, useRewardAPIV3Contract } from './useContract'
import { NULL_ADDRESS } from 'constants/Constants'
import { useSingleCallResult } from 'lib/hooks/multicall'

export interface RewardInfo {
  // externalBribeReward: (5) [Array(0), Array(0), Array(0), Array(0), '0x0000000000000000000000000000000000000000', tokens: Array(0), symbols: Array(0), decimals: Array(0), amounts: Array(0), bribe: '0x0000000000000000000000000000000000000000']
  // internalBribeReward: (5) [Array(0), Array(0), Array(0), Array(0), '0x0000000000000000000000000000000000000000', tokens: Array(0), symbols: Array(0), decimals: Array(0), amounts: Array(0), bribe: '0x0000000000000000000000000000000000000000']
  // totalVotesOnGauge: BigNumber {_hex: '0x04bdeefb8b7c354b0d68', _isBigNumber: true}
  // totalVotesOnGaugeByUser: BigNumber {_hex: '0x00', _isBigNumber: true}
  // _externalBribeAddress: "0xe30D4f9CE58d935C3AC8819C96055B8C0957B467"
  // _gauge: "0x39B2299BAC951b3Bbdd5bfEFe5fFa5AA888A704f"
  // _internalBribeAddress: "0x7956BBc2134Fb9c53EB6Ea6A7277eF52b6E0f85f"
  // _pool: "0x9a56F822bAbFbfDC2F25Cb34eB952ff38Af869d8"

  externalBribeReward: {
    tokens: string[]
    symbols: string[]
    decimals: number[]
    amounts: string[]
    bribe: string
  }
  internalBribeReward: {
    tokens: string[]
    symbols: string[]
    decimals: number[]
    amounts: string[]
    bribe: string
  }
  /*
   * BigNumber
   */
  totalVotesOnGauge: string
  /*
   * BigNumber
   */
  totalVotesOnGaugeByUser: string
  /*
   * address
   */
  _externalBribeAddress: string
  _gauge: string
  /*
   * address
   */
  _internalBribeAddress: string
  /*
   * address
   */
  _pool: string
}

const parseResult = (result: any): RewardInfo => {
  return {
    externalBribeReward: {
      tokens: result.externalBribeReward.tokens,
      symbols: result.externalBribeReward.symbols,
      decimals: result.externalBribeReward.decimals.map((d: BigNumber) => d.toNumber()),
      amounts: result.externalBribeReward.amounts.map((a: BigNumber) => a.toHexString()),
      bribe: result.externalBribeReward.bribe,
    },
    internalBribeReward: {
      tokens: result.internalBribeReward.tokens,
      symbols: result.internalBribeReward.symbols,
      decimals: result.internalBribeReward.decimals.map((d: BigNumber) => d.toNumber()),
      amounts: result.internalBribeReward.amounts.map((a: BigNumber) => a.toHexString()),
      bribe: result.internalBribeReward.bribe,
    },
    totalVotesOnGauge: result.totalVotesOnGauge.toHexString(),
    totalVotesOnGaugeByUser: result.totalVotesOnGaugeByUser.toHexString(),
    _externalBribeAddress: result._externalBribeAddress.toLowerCase(),
    _gauge: result._gauge.toLowerCase(),
    _internalBribeAddress: result._internalBribeAddress.toLowerCase(),
    _pool: result._pool.toLowerCase(),
  }
}

/**
 * Get total voting power
 */
export function useGetAllClPairRewards(): RewardInfo[] | null {
  const RewardApiV3Contract = useRewardAPIV3Contract()
  // const blockNumber = useBlockNumber()
  const result = useSingleCallResult(
    RewardApiV3Contract ? RewardApiV3Contract : null,
    'getAllCLPairRewards',
    [NULL_ADDRESS, 50, 0],
    { gasRequired: 8000000, blocksPerFetch: Infinity }
  )

  const [Rewards, setRewards] = useState<RewardInfo[] | null>(null)

  useEffect(() => {
    if (!RewardApiV3Contract) return
    if (!result) return
    if (result.loading) return
    if (result.result) {
      setRewards(
        result.result.Pairs.filter((pair: any) => pair && pair._pool !== NULL_ADDRESS).map(
          (result: any): RewardInfo => parseResult(result)
        )
      )
    }
    return
  }, [RewardApiV3Contract, result.loading])
  return Rewards

  // return Pairs
}

/**
 * Get total voting power
 */
export function useGetAllPairRewards(): RewardInfo[] | null {
  const RewardApiV3Contract = useRewardAPIV3Contract()
  // const blockNumber = useBlockNumber()
  const result = useSingleCallResult(
    RewardApiV3Contract ? RewardApiV3Contract : null,
    'getAllPairRewards',
    [NULL_ADDRESS, 50, 0],
    { gasRequired: 8000000, blocksPerFetch: Infinity }
  )

  const [Rewards, setRewards] = useState<RewardInfo[] | null>(null)

  useEffect(() => {
    if (!RewardApiV3Contract) return
    if (!result) return
    if (result.loading) return
    if (result.result) {
      setRewards(
        result.result.Pairs.filter((pair: any) => pair && pair._pool !== NULL_ADDRESS).map(
          (result: any): RewardInfo => parseResult(result)
        )
      )
    }
    return
  }, [RewardApiV3Contract, result.loading])
  return Rewards

  // return Pairs
}
